<template>
  <div class="index">
    <div class="header" id="header">
      <div class="box">
        <img class="logo"  @click="handleTabClick('home')" src="../../assets/newIndex/yllogo.png" alt="logo" height="31px" />
        <ul>
          <li @click="handleTabClick('home')">
            首页
            <div v-if="active == 'home'" class="line"></div>
          </li>
          <li @click="handleTabClick('cabinet')">
            智能柜
            <div v-if="active == 'cabinet'" class="line"></div>
          </li>
          <li @click="handleTabClick('programme')">
            解决方案
            <div v-if="active == 'programme'" class="line"></div>
          </li>
          <li @click="handleTabClick('practice')">
            最佳实践
            <div v-if="active == 'practice'" class="line"></div>
          </li>
          <li @click="handleTabClick('download')">
            即刻体验
            <div v-if="active == 'download'" class="line"></div>
          </li>
          <li @click="handleTabClick('hope')">
            帮助中心
            <div v-if="active == 'hope'" class="line"></div>
          </li>
          <li class="btn-login"  @click="login">
            <a-button type="primary" class="login">登录</a-button>
          </li>
        </ul>
      </div>
    </div>
    <div id="content">
     <yy ref="yy" v-if="active == 'yy'"></yy>
    <home ref="home" v-if="active == 'home'"></home>
    <cabinet ref="cabinet" v-if="active == 'cabinet'"></cabinet>
    <programme ref="programme" v-if="active == 'programme'"></programme>
    <practice ref="practice" v-if="active == 'practice'"></practice>
    <download ref="download" v-if="active == 'download'"></download>
    <hope ref="hope" v-if="active == 'hope'" :active="active4"></hope>
    <contact ref="contact" v-if="active == 'contact'"></contact>
    <ys ref="ys" v-if="active == 'ys'"></ys>
    <fw ref="fw" v-if="active == 'fw'"></fw>
    </div>
    <div class="footer">
      <div class="footer_box">
        <div class="footer_left">
          <img src="../../assets/newIndex/footer_logo.png" class="logo" />
          <p class="logo_txt">智慧实验室设备管理工具</p>
          <img src="../../assets/newIndex/ewm.png" class="ewm" />
          <p class="ewm_txt">扫码关注公众号</p>
        </div>
        <div class="footer_right">
          <ul class="left">
            <li>
              <div class="f2 ft1"  @click="handleTabClick('hope')">服务支持</div>
            </li>
            <li>
              <div class="f2"  @click="handleTabhopeClick('hope',1)">培训与演示</div>
            </li>
            <li>
              <div class="f2"   @click="handleTabhopeClick('hope',2)">帮助中心</div>
            </li>
            <li>
              <div class="f2"  @click="handleTabhopeClick('hope',3)">视频小课堂</div>
            </li>
            <li>
              <div class="f2"  @click="handleTabhopeClick('hope',4)">下载中心</div>
            </li>
            <li>
              <div class="f2"   @click="handleTabhopeClick('hope',5)">保修承诺</div>
            </li>
          </ul>
          <ul class="right">
            <li>
              <div class="f3 ft1">毅力实验通</div>
            </li>
            <!-- <li>
              <div class="f3">更新日志</div>
            </li> -->
            <li>
              <div class="f3"   @click="handleTabClick('contact')">联系我们</div>
            </li>
            <li>
              <div class="f3" @click="handleTabClick('ys')">隐私条款</div>
            </li>
            <li>
              <div class="f3"  @click="handleTabClick('fw')">用户协议</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="line"></div>
      <div class="footer_bottom">
        <ul class="footer_bottom_ul">
          <li>©2023 毅力澎湃&德塔星技术</li>
          <li><a href="https://beian.miit.gov.cn" target="blank">京ICP备15015852号-3</a></li>
          <!-- <li>京公网安备 110108020******号</li> -->
          <li>北京毅力澎湃技术有限公司</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueSeamless from 'vue-seamless-scroll'
import home from　'./components/home'
import hope from　'./components/hope'
import download from　'./components/download'
import cabinet from　'./components/cabinet'
import practice from　'./components/practice'
import programme from　'./components/programme'
import yy from　'./components/yy'
import contact from　'./components/contact'
import ys from　'./components/ys'
import fw from　'./components/fw'
export default {
  components: {
    vueSeamless,
    home,
    hope,
    download,
    cabinet,
    practice,
    programme,
    yy,
    ys,
    fw,
    contact
  },
  data() {
    return {
      active1: '1',
      active2: '1',
      active3: '1',
      active4:1,
      active3Child: '1',
      active: 'home',
      text: '',
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2,
      }
    },
  },
  methods: {
    handleTabClick(key) {
      this.active = key
    },
    handleTabhopeClick(key,str) 
    {
      this.active4= str;
      this.active = key;
      //#
    },
    content2Click(flag) {
      this.active1 = flag
    },
    content3Click(flag) {
      this.active2 = flag
    },
    content4Click(flag) {
      this.active3 = flag
    },
    content4ChildClick(flag) {
      this.active3Child = flag
    },
    login() {
      this.$router.push({ path: '/user/login' });
    },
  },
}
</script>
<style lang="less" scoped>
.index {
  height: 100%;
  width: 100%;
  background: #fff;
  .header {
    background-color: #bbf5e8;
    height: 70px;
    .box {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      align-items: end;
      position: relative;
      .logo {
        width: 166px;
        height: 31px;
        margin-bottom: 20px;
        cursor: pointer;
      }
      ul {
        display: flex;
        margin: 0;
        height: 45px;
        padding-right: 130px;
        .btn-login {
          position: absolute !important;
          top: 17px;
          right: 10px;
          button {
            background: #eb4d27;
          }
        }
        li {
          cursor: pointer;
          position: relative;
          list-style: none;
          margin: 0px 30px;
          .login {
          }
          .line {
            position: absolute;
            bottom: 0;
            width: 12px;
            height: 3px;
            background: #eb4d27;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            left: 35%;
          }
        }
      }
    }
  }
  .banner {
    background-image: url('../../assets/newIndex/banner.png');
    height: 618px;
    max-width: 1920px;
    margin: 0 auto;
    .box {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      .left {
        position: absolute;
        left: 0;
        top: 149px;
        .font1 {
          font-size: 60px;
          color: #fff;
          font-weight: 100;
          margin-bottom: 0;
        }
        .font2 {
          font-size: 60px;
          color: #fff;
        }
        .demonstrate-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #eb4d27;
          .demonstrate {
            width: 12.5px;
            height: 12.5px;
            margin-right: 5px;
          }
        }
      }
      .right {
        width: 799px;
        height: 531px;
        margin-top: 19px;
        float: right;
      }
    }
  }
  .explain {
    width: 1200px;
    height: 341px;
    background: #fff;
    margin: -87px auto;
    z-index: 999;
    position: relative;
    border-radius: 8px 8px 8px 8px;
    ul {
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      li {
        width: 200px;
        list-style: none;
        text-align: center;
        .explain-logo {
          width: 142px;
          height: 142px;
        }
        .title {
          font-size: 30px;
          font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #000000;
        }
        .title1 {
          font-size: 22px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #999999;
        }
        p {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  .seamless-warp2 {
    display: inline-block;
    height: 100px;
    width: 100% !important;
    overflow: hidden;
    ul.item {
      width: 580px;
      li {
        float: left;
        margin-right: 10px;
      }
    }
    .index_footer {
      align-items: baseline;
    }

    .footer_menu,
    .index_footer {
      display: flex;
      justify-content: center;
    }

    .footer_menu {
      flex-direction: column;
      align-items: center;
      margin-left: 10px;
      height: 100px;
      min-width: 260px;
    }

    .footer_banner {
      min-width: 230px;
      min-height: 60px;
    }
  }
  .scrollBox {
    margin-top: 87px;
    text-align: center;
  }
  .ant-carousel :deep(.slick-slide) {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }

  .ant-carousel :deep(.slick-slide h3) {
    color: #fff;
  }
  .carousel_mobile {
    height: 400px;
    width: 200px;
  }
  .carouselBox {
    margin-top: 50px;
    .item {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      .left {
        margin-right: 235px;
      }
    }
    .left_header {
      display: flex;
      align-items: flex-start;
      height: 100px;
      .text {
        min-width: 194px;
        height: 97px;
        font-size: 40px;
        font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
        font-weight: normal;
        color: #000000;
        line-height: 41px;
      }
      .header_line {
        width: 162px;
        height: 72px;
      }
    }

    .text2 {
      min-width: 337px;
      height: 25px;
      font-size: 18px;
      font-weight: normal;
      color: #999999;
      line-height: 23px;
    }
    .text3 {
      min-width: 368px;
      height: 30px;
      font-size: 22px;
      font-weight: normal;
      color: #666666;
      line-height: 25px;
    }
  }
  .content1 {
    display: flex;
    max-width: 1920px;
    height: 405px;
    margin: 148px auto 0 auto;
    background: linear-gradient(325deg, #504c4b 0%, #ffab39 100%);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    .box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      .title1 {
        width: 400px;
        height: 102px;
        font-size: 40px;
        font-weight: normal;
        color: #ffffff;
        line-height: 46px;
      }
      .characteristic {
        margin-top: 63px;
        padding-left: 0;
        display: flex;
        li {
          .img {
            margin-right: 14px;
          }
          height: 30px;
          font-size: 22px;
          font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
          line-height: 26px;
          margin-right: 37px;
        }
      }
      .describe {
        width: 403px;
        height: 48px;
        font-size: 16px;
        font-weight: normal;
        color: #ffffff;
        line-height: 26px;
      }
      .left_box {
        padding-top: 76px;
      }
      .right_img {
        margin-left: 50px;
      }
      .right1 {
        max-width: 528px;
        min-width: 428px;
        height: 405px;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }
  }
  .content2 {
    max-width: 1920px;
    height: 543px;
    background: url('../../assets/newIndex/content2.png');
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin: 0 auto;
    ul {
      width: 1200px;
      padding: 0;
      margin: 0 auto;
      display: flex;
      li {
        height: 311px;
        width: 161px;
        margin-right: 10px;
        background: #fff;
        margin-top: 115px;
        border-radius: 8px 8px 8px 8px;
        position: relative;
        .describe {
          padding-top: 49px;
          padding-left: 32px;
        }
        .right_img {
          position: absolute;
          bottom: 47px;
          left: 32px;
          width: 55px;
          height: 55px;
          line-height: 55px;
          text-align: center;
          border: 1px solid #f9c9bd;
          border-radius: 50%;
        }
        .active_box {
          padding-left: 45px;
          .active_title {
            min-width: 152px;
            height: 30px;
            font-size: 22px;
            font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #ffffff;
            margin-top: 43px;
          }
          .active_line {
            margin-top: 19px;
            margin-left: 1px;
            width: 20px;
            height: 4px;
            background: #ffffff;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
          }
          .active_describe {
            width: 257px;
            height: 64px;
            font-size: 14px;
            font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #ffffff;
            line-height: 22px;
            margin-top: 86px;
          }
        }
      }
      .active {
        width: 345px;
        height: 311px;
        background-image: url('../../assets/newIndex/bk_active.png');
        width: 345px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
      }
    }
  }
  .content3 {
    max-width: 1920px;
    height: 827px;
    background: url('../../assets/newIndex/content3.png');
    position: relative;
    margin: 0 auto;
    .footer_box {
      position: absolute;
      bottom: 0;
    }
    .title {
      padding-top: 130px;
      font-size: 40px;
      font-weight: normal;
      color: #fdf1e9;
      line-height: 46px;
      height: 180px;
      width: 1200px;
      text-align: center;
    }
    .describe {
      margin-top: 15px;
      width: 722px;
      height: 28px;
      font-size: 20px;
      font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #fdf1e9;
      line-height: 26px;
      width: 1200px;
      margin: 15px auto;
      text-align: center;
    }
    ul {
      width: 1200px;
      padding: 0;
      margin: 0 auto;
      display: flex;
      align-items: flex-end;
      margin-top: 152px;
      li {
        width: 293px;
        height: 388px;
        background: #ffffff;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        margin-right: 10px;
        display: flex;
        align-items: center !important;
        flex-direction: column;

        .img_logo {
          width: 120px;
          height: 163px;
          padding-top: 43px;
        }
        .li_title {
          min-width: 142px;
          height: 33px;
          font-size: 24px;
          font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #eb4d27;
          line-height: 38px;
          margin-top: 28px;
        }
        .li_footer {
          width: 100%;
          height: 164px;
          margin-top: 48px;
          .li_describe {
            width: 144px;
            height: 62px;
            font-size: 14px;
            font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #999999;
            line-height: 21px;
            margin: 0 auto;
            text-align: center;
          }
        }
        .li_active1 {
          background: linear-gradient(180deg, rgba(81, 218, 190, 0) 0%, #51dabe 100%);
          border-radius: 0px 0px 0px 0px;
          opacity: 0.42;
        }
      }
      .li_active {
        width: 293px;
        height: 450px;
        background: #ffffff;
        box-shadow: 0px -5px 23px 1px rgba(84, 255, 203, 0.88);
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        border: 2px solid #54ffcb;
        z-index: 999;
      }
    }
  }
  .content4 {
    max-width: 1920px;
    height: 799px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin: 0 auto;
    .content4_header {
      padding-top: 179px;
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: center;
      li {
        cursor: pointer;
        min-width: 79px;
        height: 56px;
        font-size: 40px;
        font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
        font-weight: normal;

        // line-height: 108px;
        margin-left: 68px;
        .txt {
          color: #999999;
        }
        .active {
          color: #000000;
        }
        .line {
          margin-top: 7px;
          width: 68px;
          height: 8px;
          background: linear-gradient(90deg, #14d0b4 0%, #66ddc1 100%);
          box-shadow: 0px 3px 6px 1px rgba(20, 208, 180, 0.27);
          border-radius: 188px 188px 188px 188px;
          opacity: 1;
        }
      }
    }
    .content4_content {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: center;
      margin-top: 69px;
      .content4_content_active {
        background: #ffffff;
        box-shadow: 0px 19px 27px 1px rgba(235, 77, 39, 0.19);
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        border: 2px solid #eb4d27;
        .li_title {
          color: #000000 !important;
        }
        .li_describe {
          color: #999999 !important;
        }
      }
      li {
        width: 267px;
        height: 304px;
        background: linear-gradient(180deg, #14d0b4 0%, #66ddc1 100%);
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        margin-right: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img_logo {
          padding-top: 51px;
          height: 119px;
          width: 58px;
        }
        .li_title {
          margin-top: 30px;
          min-width: 48px;
          height: 30px;
          font-size: 22px;
          font-family: Alibaba PuHuiTi 3-55 Regular, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
        }
        .li_describe {
          width: 178px;
          height: 44px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #ffffff;
          padding-top: 18px;
          text-align: center;
        }
      }
    }
  }
  .content5 {
    //content5.png
    max-width: 1920px;
    height: 558px;
    background: url('../../assets/newIndex/content5.png');
    background-size: cover;
    margin: 0 auto;
    position: relative;
    .content5_box {
      position: absolute;
      left: 500px;
      top: 100px;
      width: 500px;
      height: 270px;
      padding-top: 50px;
      text-align: center;
      background: #fff;
      .tit {
        min-width: 354px;
        height: 56px;
        font-size: 40px;
        font-family: Alibaba PuHuiTi 3-65 Medium, Alibaba PuHuiTi 30;
        font-weight: normal;
        color: #000000;
      }
      .desc {
        margin-top: 5px;
        min-width: 230px;
        height: 25px;
        font-size: 18px;
        font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
        font-weight: normal;
        color: #000000;
      }
      .footer_action {
        display: flex;
        justify-content: center;
        margin-top: 35px;
        .f_left {
          color: #ffffff;
          width: 100px;
          height: 50px;
          background: linear-gradient(90deg, #14d0b4 0%, #66ddc1 100%);
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        }
        .f_right {
          width: 131px;
          height: 50px;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 1px solid #999999;
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            width: 12.5px;
            height: 12.5px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .footer {
    background: #f9fcfe;
    .line {
      width: 100%;
      height: 0px;
      opacity: 0.5;
      border: 1px solid rgba(112, 112, 112, 0.26);
    }

    .footer_box {
      width: 1200px;
      margin: 0 auto;
      height: 385px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      .footer_left {
        .logo {
          width: 220px;
          height: 98px;
          padding-top: 59px;
        }
        .logo_txt {
          min-width: 173px;
          height: 22px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #999999;
          line-height: 38px;
          margin-top: 10px;
        }
        .ewm {
          width: 109px;
          height: 109px;
          margin-top: 40px;
        }
        .ewm_txt {
          min-width: 110px;
          height: 22px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #000000;
          line-height: 38px;
          margin-top: 6px;
        }
      }
      .footer_right {
        margin-right: 100px;
        display: flex;
        .left {
          margin-right: 115px;
        }
        ul {
          padding-top: 58px;
          padding-left: 0;
          .ft1 {
            min-width: 63px;
            height: 22px;
            font-size: 16px;
            font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #000000;
            line-height: 38px;
            margin-bottom: 15px;
          }
          li {
            cursor: pointer;
            padding-top: 16px;
            min-width: 55px;
            height: 36px;
            font-size: 14px;
            font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #999999;
            line-height: 38px;
          }
        }
      }
    }

    .footer_bottom {
      width: 1200px;
      margin: 0 auto;

      ul {
        display: flex;
        justify-content: right;
        li {
          min-width: 63px;
          height: 17px;
          font-size: 12px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #a7a7a7;
          line-height: 38px;
          margin-right: 43px;
        }
      }
      ul li:last-child {
        margin-right: 0;
      }
    }
  }
}
ul li {
  list-style: none;
}
a{
  text-decoration:none;
  color:#a7a7a7;
}
</style>